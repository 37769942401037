/*REACT*/
import React from 'react';

/*COMPONENTS*/
import SliderGreeting from '../components/SliderCustom/SliderGreeting';

export default function GreetingPage() {
    return (
        <>
            <SliderGreeting />
        </>
    )
}