import * as React from "react";

function SvgPlacemark(props) {
  return (
    <svg width={17} height={25} {...props}>
      <path d="M8.5 0.998047C3.80071 0.998047 0 4.75405 0 9.39805C0 15.698 8.5 24.998 8.5 24.998C8.5 24.998 17 15.698 17 9.39805C17 4.75405 13.1993 0.998047 8.5 0.998047ZM2.42857 9.39805C2.42857 6.08605 5.14857 3.39805 8.5 3.39805C11.8514 3.39805 14.5714 6.08605 14.5714 9.39805C14.5714 12.854 11.0743 18.026 8.5 21.254C5.97429 18.05 2.42857 12.818 2.42857 9.39805Z" />
      <path d="M8.5 12.398C10.1766 12.398 11.5357 11.0549 11.5357 9.39805C11.5357 7.74119 10.1766 6.39805 8.5 6.39805C6.82342 6.39805 5.46429 7.74119 5.46429 9.39805C5.46429 11.0549 6.82342 12.398 8.5 12.398Z" fill="black"/>
    </svg>
  );
}

export default SvgPlacemark;