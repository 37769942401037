import * as React from "react";

function SvgArrowRight(props) {
  return (
    <svg width={33} height={32} {...props}>
        <path d="M2.41634 18.0834H25.6872L15.5205 28.25C14.708 29.0625 14.708 30.3959 15.5205 31.2084C16.333 32.0209 17.6455 32.0209 18.458 31.2084L32.1872 17.4792C32.9997 16.6667 32.9997 15.3542 32.1872 14.5417L18.4788 0.791687C17.6663 -0.020813 16.3538 -0.020813 15.5413 0.791687C14.7288 1.60419 14.7288 2.91669 15.5413 3.72919L25.6872 13.9167H2.41634C1.27051 13.9167 0.333008 14.8542 0.333008 16C0.333008 17.1459 1.27051 18.0834 2.41634 18.0834Z"/>
    </svg>
  );
}

export default SvgArrowRight;