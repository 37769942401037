import * as React from "react";

function SvgArrowDown(props) {
  return (
    <svg width={75} height={95} {...props}>
      <path d="M32.5609 6.09658L32.5609 74.1954L8.45818 44.4441C6.53194 42.0664 3.37093 42.0664 1.44468 44.4441C-0.48156 46.8218 -0.48156 50.6626 1.44468 53.0403L33.9932 93.2168C35.9195 95.5944 39.0311 95.5944 40.9574 93.2168L73.5553 53.1012C75.4816 50.7236 75.4816 46.8827 73.5553 44.5051C71.6291 42.1274 68.5174 42.1274 66.5912 44.5051L42.4391 74.1954L42.4391 6.09658C42.4391 2.74346 40.2165 0 37.5 0C34.7835 0 32.5609 2.74346 32.5609 6.09658Z" />
    </svg>
  );
}

export default SvgArrowDown;
