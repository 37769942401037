import * as React from "react";

function SvgArrowUp(props) {
  return (
    <svg width={20} height={12} {...props}>
        <path d="M3.53385 10.5159L10.0005 4.04928L16.4672 10.5159C17.1172 11.1659 18.1672 11.1659 18.8172 10.5159C19.4672 9.86594 19.4672 8.81594 18.8172 8.16594L11.1672 0.515942C10.5172 -0.134058 9.46719 -0.134058 8.81719 0.515942L1.16719 8.16594C0.517188 8.81594 0.517188 9.86594 1.16719 10.5159C1.81719 11.1493 2.88385 11.1659 3.53385 10.5159Z" />
    </svg>
  );
}

export default SvgArrowUp;