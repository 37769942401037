import * as React from "react";

function SvgSearch(props) {
  return (
    <svg width={32} height={30} {...props}>
        <path d="M11.5414 0C14.6024 0 17.538 1.14137 19.7024 3.17301C21.8668 5.20465 23.0828 7.96016 23.0828 10.8333C23.0828 13.5167 22.0352 15.9833 20.3129 17.8833L20.7923 18.3333H22.195L31.073 26.6667L28.4096 29.1667L19.5316 20.8333V19.5167L19.0522 19.0667C17.028 20.6833 14.4001 21.6667 11.5414 21.6667C8.48043 21.6667 5.54483 20.5253 3.3804 18.4937C1.21596 16.462 0 13.7065 0 10.8333C0 7.96016 1.21596 5.20465 3.3804 3.17301C5.54483 1.14137 8.48043 0 11.5414 0ZM11.5414 3.33333C7.1024 3.33333 3.5512 6.66667 3.5512 10.8333C3.5512 15 7.1024 18.3333 11.5414 18.3333C15.9804 18.3333 19.5316 15 19.5316 10.8333C19.5316 6.66667 15.9804 3.33333 11.5414 3.33333Z"/>
    </svg>
  );
}

export default SvgSearch;