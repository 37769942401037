import * as React from "react";

function SvgHome(props) {
  return (
    <svg width={30} height={34} {...props}>
        <path d="M28.3333 10.3612L18.3334 1.60501C17.4167 0.786066 16.2299 0.333313 15.0001 0.333313C13.7702 0.333313 12.5834 0.786066 11.6667 1.60501L1.66681 10.3612C1.13743 10.8341 0.714986 11.4142 0.427608 12.0629C0.14023 12.7116 -0.00548756 13.414 0.000157989 14.1234V28.6726C0.000157989 29.9971 0.526939 31.2674 1.46461 32.2039C2.40229 33.1405 3.67405 33.6666 5.00012 33.6666H25C26.3261 33.6666 27.5978 33.1405 28.5355 32.2039C29.4732 31.2674 29.9999 29.9971 29.9999 28.6726V14.1067C30.0032 13.4002 29.8564 12.701 29.5691 12.0553C29.2818 11.4096 28.8606 10.8322 28.3333 10.3612ZM18.3334 30.3373H11.6667V22.0139C11.6667 21.5724 11.8423 21.149 12.1549 20.8368C12.4675 20.5246 12.8914 20.3492 13.3334 20.3492H16.6667C17.1087 20.3492 17.5327 20.5246 17.8452 20.8368C18.1578 21.149 18.3334 21.5724 18.3334 22.0139V30.3373ZM26.6666 28.6726C26.6666 29.1141 26.491 29.5375 26.1785 29.8497C25.8659 30.1619 25.442 30.3373 25 30.3373H21.6667V22.0139C21.6667 20.6894 21.1399 19.4192 20.2022 18.4826C19.2645 17.5461 17.9928 17.0199 16.6667 17.0199H13.3334C12.0073 17.0199 10.7356 17.5461 9.79789 18.4826C8.86021 19.4192 8.33343 20.6894 8.33343 22.0139V30.3373H5.00012C4.5581 30.3373 4.13418 30.1619 3.82162 29.8497C3.50906 29.5375 3.33347 29.1141 3.33347 28.6726V14.1067C3.33377 13.8704 3.38446 13.6368 3.48216 13.4215C3.57987 13.2062 3.72235 13.0142 3.90013 12.8582L13.9001 4.11867C14.2042 3.85179 14.5952 3.70461 15.0001 3.70461C15.4049 3.70461 15.7959 3.85179 16.1 4.11867L26.1 12.8582C26.2778 13.0142 26.4202 13.2062 26.5179 13.4215C26.6156 13.6368 26.6663 13.8704 26.6666 14.1067V28.6726Z"/>
    </svg>
  );
}

export default SvgHome;