import * as React from "react";

function SvgEmail(props) {
  return (
    <svg width={40} height={40} {...props}>
      <path d="M36.6663 9.99984C36.6663 8.1665 35.1663 6.6665 33.333 6.6665H6.66634C4.83301 6.6665 3.33301 8.1665 3.33301 9.99984V29.9998C3.33301 31.8332 4.83301 33.3332 6.66634 33.3332H33.333C35.1663 33.3332 36.6663 31.8332 36.6663 29.9998V9.99984ZM33.333 9.99984L19.9997 18.3332L6.66634 9.99984H33.333ZM33.333 29.9998H6.66634V13.3332L19.9997 21.6665L33.333 13.3332V29.9998Z" />
    </svg>
  );
}

export default SvgEmail;